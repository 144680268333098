import {
  FC,
  HTMLAttributes,
  KeyboardEventHandler,
  ReactNode,
  useEffect,
  useState,
} from "react";

import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";

interface ExpandableParagraphProps
  extends HTMLAttributes<HTMLParagraphElement> {
  children: ReactNode;
  numberOfLines?: number;
}

const MIN_CHARACTER_LENGTH = 130;

export const ExpandableParagraph: FC<ExpandableParagraphProps> = ({
  children,
  className,
  numberOfLines = 2,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const expandingDisabled =
    (typeof children === "string" && children.length < MIN_CHARACTER_LENGTH) ||
    typeof children !== "string";

  useEffect(() => {
    if (
      typeof children === "string" &&
      children.length < MIN_CHARACTER_LENGTH
    ) {
      setIsExpanded(true);
    }
  }, [children]);

  const onKeyDown: KeyboardEventHandler<HTMLParagraphElement> = (event) => {
    if (event.key === "Enter") {
      setIsExpanded(!isExpanded);
    }
  };

  const onClick = () => {
    setIsExpanded(!isExpanded);
  };

  if (!children) {
    return null;
  }

  return (
    <motion.div
      variants={{
        expanded: { height: "auto" },
        collapsed: {
          height: expandingDisabled ? "auto" : `${20 * numberOfLines}px`,
        },
      }}
      animate={isExpanded ? "expanded" : "collapsed"}
      onKeyDown={onKeyDown}
      onClick={onClick}
      role="button"
      tabIndex={0}
      aria-expanded={isExpanded}
      aria-label={isExpanded ? "Show less" : "Show more"}
      className={twMerge(
        "text-muted-foreground text-sm cursor-pointer overflow-hidden",
        !isExpanded && `line-clamp-${numberOfLines}`, // use dynamic number of lines
        className
      )}
    >
      {children}
    </motion.div>
  );
};

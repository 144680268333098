import { ReactNode, useContext } from "react";

import { Accordion, AccordionContent, AccordionItem } from "../accordion";
import { RowContext } from "./row-context";

interface ExpandableContentProps<T> {
  children: (data: T) => ReactNode;
}

export const ExpandableContent = <T,>({
  children,
}: ExpandableContentProps<T>) => {
  const { row, isExpanded } = useContext(RowContext);

  return (
    <Accordion
      type={"single"}
      value={isExpanded ? "1" : undefined}
      aria-label="Expandable row content"
    >
      <AccordionItem
        value={"1"}
        className={isExpanded ? "border-t-none" : "border-none"}
        aria-expanded={isExpanded}
      >
        <AccordionContent role="region">
          {children(row?.original as T)}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

import { DocumentType, DocumentTypeWithCount } from "@atlas-ui/types";

import { isEmpty } from "lodash";

import API from "./api";

interface GetDocumentTypesParams {
  withDocuments?: boolean;
  insightsEnabled?: boolean;
}

export const getDocumentTypes = async (
  { withDocuments, insightsEnabled }: GetDocumentTypesParams = {
    withDocuments: false,
    insightsEnabled: false,
  }
) => {
  try {
    const response = await API.get(`/document-types`, {
      params: {
        withDocuments,
        insightsEnabled,
      },
    });

    return response.data as DocumentType[];
  } catch (error) {
    throw new Error(
      (error as Error)?.message || "Error getting document types"
    );
  }
};

export interface GetDocumentTypesWithCountParams {
  tags?: string[];
  parties?: string[];
  searchValue?: string;
  tab?: string;
}

export const getDocumentTypesWithCount = async ({
  tags,
  parties,
  searchValue,
  tab,
}: GetDocumentTypesWithCountParams) => {
  try {
    const response = await API.get(`/document-types/count`, {
      params: {
        tags: tags?.length
          ? tags.map((tag) => `"${tag}"`).join(",")
          : undefined,
        parties: parties?.length
          ? parties.map((party) => `"${party}"`).join(",")
          : undefined,
        search: searchValue && !isEmpty(searchValue) ? searchValue : undefined,
        tab: tab && !isEmpty(tab) ? tab : undefined,
      },
    });

    return response.data as DocumentTypeWithCount[];
  } catch (error) {
    throw new Error(
      (error as Error)?.message || "Error getting document types"
    );
  }
};

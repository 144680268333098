// FILE: LoadingButton.tsx
import React, { ReactNode } from "react";

import {
  Button,
  ButtonProps,
  Spinner,
  SpinnerProps,
} from "@atlas-ui/components";

interface LoadingButtonProps extends ButtonProps {
  isLoading: boolean;
  loadingText?: ReactNode;
  children: React.ReactNode;
  spinnerProps?: SpinnerProps;
}

export const LoadingButton: React.FC<LoadingButtonProps> = ({
  isLoading,
  onClick,
  className,
  children,
  loadingText,
  spinnerProps,
  "aria-label": ariaLabel,
  ...rest
}) => {
  const defaultLoadingText = "Loading...";
  const buttonText = loadingText || defaultLoadingText;

  return (
    <Button
      className={className}
      onClick={onClick}
      disabled={isLoading}
      data-isloading={isLoading}
      aria-busy={isLoading}
      aria-label={ariaLabel}
      {...rest}
    >
      {isLoading ? (
        <div className="flex items-center gap-2" aria-hidden="true">
          <Spinner
            size={4}
            className="w-4 h-4"
            containerClass="w-4 h-4"
            aria-label={`${buttonText}`}
            {...spinnerProps}
          />
          {loadingText}
        </div>
      ) : (
        children
      )}
      {isLoading && <span className="sr-only">{buttonText}</span>}
    </Button>
  );
};

import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useInfiniteQuery, useQueryClient } from "react-query";

import {
  defaultMessage,
  defaultTitle,
  ErrorContext,
} from "@atlas-ui/components";
import { getDocumentsByTab, postDocument } from "@atlas-ui/services";
import {
  Document,
  DocumentStatus,
  DocumentTab,
  PaginatedResponse,
} from "@atlas-ui/types";
import { parseArrayToString } from "@atlas-ui/utils";

import { useParties } from "@/app/compare/hooks/use-parties";
import { useInProgressDocuments } from "@/app/documents/[id]/hooks/use-in-progress-documents";
import { IN_PROGRESS_QUERY_KEY } from "@/app/documents/[id]/lib/constants";
import { useMyDocumentsWebsocketEvents } from "@/app/my-documents/hooks/use-my-documents-websocket-events";
import {
  DOCUMENT_TYPE_LOCALSTORAGE_KEY,
  ITEMS_PER_PAGE,
  PARTIES_LOCALSTORAGE_KEY,
  SEARCH_BAR_LOCALSTORAGE_KEY,
  SORTING_OPTION_LOCALSTORAGE_KEY,
  TAB_LOCALSTORAGE_KEY,
  TAGS_LOCALSTORAGE_KEY,
} from "@/app/my-documents/lib/constants";
import {
  addExtensionToFile,
  InvalidFile,
} from "@/lib/common/helpers/files-helper";
import { useDocumentTypes } from "@/lib/common/hooks/use-document-types";
import { useTags } from "@/lib/common/hooks/use-tags";

import { set, uniqBy } from "lodash";
import mixpanel from "mixpanel-browser";

import { MyDocumentsContextType } from "../lib/my-documents-context";
import {
  getFromLocalStorage,
  parseSortingOption,
  removeFailedDocuments,
  saveToLocalStorage,
} from "../lib/utils";

interface State {
  searchValue: string;
  sortingOption: string;
  isUploadCardOpen: boolean;
  isUploadingFiles: boolean;
  currentPage: number;
  selections: {
    documentTypeId: string;
    tags: string[];
    parties: string[];
    tab: DocumentTab;
  };
}

const getInitialState = (): State => ({
  searchValue: "",
  sortingOption: getFromLocalStorage(
    SORTING_OPTION_LOCALSTORAGE_KEY,
    "createdAt:desc"
  ),
  isUploadCardOpen: false,
  isUploadingFiles: false,
  currentPage: 1,
  selections: {
    documentTypeId: getFromLocalStorage(
      DOCUMENT_TYPE_LOCALSTORAGE_KEY,
      undefined
    ),
    tags: getFromLocalStorage(TAGS_LOCALSTORAGE_KEY, []),
    parties: getFromLocalStorage(PARTIES_LOCALSTORAGE_KEY, []),
    tab: getFromLocalStorage(TAB_LOCALSTORAGE_KEY, DocumentTab.ACTIVE),
  },
});

export const MAX_DOCUMENTS_IN_PROGRESS = 10;

// 1. Add type safety for query key
type DocumentsQueryKey = [
  "documents",
  string,
  string,
  string,
  string,
  string,
  string
];

export const useMyDocuments = () => {
  const { setError } = useContext(ErrorContext);
  const queryClient = useQueryClient();
  const postedDocuments = useRef<string[]>(
    getFromLocalStorage("postedDocuments", [])
  );

  const [state, setState] = useState(getInitialState());

  const { documentTypes } = useDocumentTypes({
    withDocuments: true,
    fetchCount: true,
    params: {
      tags: state.selections.tags,
      parties: state.selections.parties,
      searchValue: state.searchValue,
      tab: state.selections.tab,
    },
  });

  const { tags } = useTags({
    documentTypeIds: state.selections.documentTypeId
      ? [state.selections.documentTypeId]
      : [],
  });

  const { parties } = useParties({
    documentTypeIds: state.selections.documentTypeId
      ? [state.selections.documentTypeId]
      : undefined,
  });

  // Saves the current filters to local storage, so they are persisted on page reload
  useEffect(() => {
    saveToLocalStorage(TAB_LOCALSTORAGE_KEY, state.selections.tab);
    saveToLocalStorage(
      DOCUMENT_TYPE_LOCALSTORAGE_KEY,
      state.selections.documentTypeId
    );
    saveToLocalStorage(SEARCH_BAR_LOCALSTORAGE_KEY, state.searchValue);
    saveToLocalStorage(TAGS_LOCALSTORAGE_KEY, state.selections.tags);
    saveToLocalStorage(PARTIES_LOCALSTORAGE_KEY, state.selections.parties);
    saveToLocalStorage(SORTING_OPTION_LOCALSTORAGE_KEY, state.sortingOption);
  }, [state]);

  useEffect(() => {
    saveToLocalStorage("postedDocuments", postedDocuments.current);
  }, [postedDocuments.current]);

  const queryKey: DocumentsQueryKey = useMemo(
    () => [
      "documents",
      state.selections.tab ?? "",
      state.searchValue ?? "",
      state.sortingOption ?? "",
      state.selections.documentTypeId ?? "",
      state.selections.parties?.join(",") ?? "",
      state.selections.tags?.join(",") ?? "",
    ],
    [state]
  );

  const { data, fetchNextPage, isFetchingNextPage, isFetching, isLoading } =
    useInfiniteQuery<PaginatedResponse<Document>>({
      queryKey: queryKey,
      staleTime: 1000 * 60, // 1 minute
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => {
        if (lastPage.total <= lastPage.result.length) {
          return undefined;
        }

        return lastPage.skip + ITEMS_PER_PAGE;
      },
      queryFn: async ({ pageParam: skip }) => {
        const [sorting, sortDirection] = parseSortingOption(
          state.sortingOption
        );

        const documentTypeIds = state.selections.documentTypeId;

        // As the parties & tags may contain spaces, we need to wrap them in quotes
        const formattedParties = parseArrayToString(state.selections.parties);
        const formattedTags = parseArrayToString(state.selections.tags);

        const documentPaginatedResponse = await getDocumentsByTab(
          state.selections.tab,
          {
            skip,
            search: state.searchValue,
            take: ITEMS_PER_PAGE,
            sortBy: sorting ?? undefined,
            sortDirection: sortDirection ?? undefined,
            documentTypeIds,
            parties: formattedParties,
            tags: formattedTags,
          }
        );

        await removeFailedDocuments(documentPaginatedResponse.result);

        const dedupedDocuments = uniqBy(
          documentPaginatedResponse.result.filter(
            (document) => document.status !== DocumentStatus.preprocessFailed
          ),
          "id"
        );

        return {
          ...documentPaginatedResponse,
          result: dedupedDocuments,
        };
      },
    });

  const isLoadingDocuments = (isLoading || isFetching) && !data?.pages.length;

  const isFiltersApplied = () => {
    return (
      state.searchValue !== "" ||
      state.selections.parties.length > 0 ||
      state.selections.tags.length > 0
    );
  };

  const {
    documents: inProgressDocuments,
    validDocuments: validInProgressDocuments,
    canUploadMoreDocuments,
  } = useInProgressDocuments();

  const allDocuments = useMemo<Document[]>(() => {
    const inProgressIds = new Set(inProgressDocuments.map((doc) => doc.id));
    return uniqBy(
      data?.pages
        .flatMap((page) => page.result)
        .filter((doc) => !inProgressIds.has(doc.id)) ?? [],
      "id"
    );
  }, [data, inProgressDocuments]);

  const uploadFile = async (file: File): Promise<Document | undefined> => {
    try {
      const postedDocument = await postDocument({
        files: [addExtensionToFile(file)],
      });

      postedDocuments.current.push(postedDocument.id);

      makeUpdateSetter("isUploadCardOpen")(false);
      makeUpdateSetter("isUploadingFiles")(false);

      return postedDocument;
    } catch (error: unknown) {
      setError({
        title: (error as Error).name ?? defaultTitle,
        message: (error as Error).message ?? defaultMessage,
      });
      return undefined;
    }
  };

  const makeUpdateSetter = (key: string) => (data: unknown) => {
    let finalUpdateObject: Partial<State> = { [key]: data };

    if (key.includes(".")) {
      finalUpdateObject = set({}, key, data);
    }

    setState((prevState) => ({
      ...prevState,
      ...finalUpdateObject,
      selections: {
        ...prevState.selections,
        ...finalUpdateObject.selections,
        parties:
          finalUpdateObject.selections?.parties ??
          prevState.selections.parties ??
          [],
        tags:
          finalUpdateObject.selections?.tags ?? prevState.selections.tags ?? [],
        tab:
          finalUpdateObject.selections?.tab ??
          prevState.selections.tab ??
          DocumentTab.ACTIVE,
      },
    }));
  };

  const uploadDocuments = async (
    validFiles: File[],
    invalidFiles: InvalidFile[],
    skipLoading = false
  ) => {
    if (!skipLoading) {
      makeUpdateSetter("isUploadingFiles")(true);
    }

    try {
      const files = Array.from(validFiles);

      const postedDocumentResults = await Promise.allSettled(
        files.map(uploadFile)
      );

      const postedDocuments: Document[] = postedDocumentResults
        .map((result) => (result.status === "fulfilled" ? result.value : null))
        .filter(Boolean) as Document[];

      queryClient.setQueryData(
        IN_PROGRESS_QUERY_KEY,
        (oldData?: Document[]) => {
          if (!oldData) return postedDocuments;

          if (invalidFiles) {
            const invalidDocuments = invalidFiles.map(
              ({ file, reason }) =>
                ({
                  id: file.name,
                  name: file.name,
                  status: DocumentStatus.preprocessFailed,
                  _invalidReason: reason,
                } as Document)
            );

            return [...invalidDocuments, ...postedDocuments, ...oldData];
          }

          return [...oldData, ...postedDocuments];
        }
      );

      mixpanel.track("action-success-new-contract", {
        count: files.length,
      });

      // We don't refresh here because the websocket event should handle that
    } catch (err) {
      setError({
        title: (err as Error).name ?? defaultTitle,
        message: (err as Error).message ?? defaultMessage,
      });
    } finally {
      makeUpdateSetter("isUploadingFiles")(false);
      makeUpdateSetter("isUploadCardOpen")(false);
    }
  };

  const onUploadClick = () => {
    makeUpdateSetter("isUploadCardOpen")(!state.isUploadCardOpen);

    if (!state.isUploadCardOpen) {
      mixpanel.track("action-intention-new-contract");
    }
  };

  useMyDocumentsWebsocketEvents({
    documentTypeId: state.selections.documentTypeId,
    currentTab: state.selections.tab,
    currentQueryKey: queryKey,
    currentPage: state.currentPage,
    postedDocuments,
  });

  const setSelectedParties = (parties: string[]) => {
    makeUpdateSetter("selections.parties")(parties);
  };

  const setSelectedTags = (tags: string[]) => {
    makeUpdateSetter("selections.tags")(tags);
  };

  const myDocumentsContext = useMemo<MyDocumentsContextType>(
    () => ({
      currentPage: data?.pages.length ?? 0,
      documentTypes,
      isFetchingNextPage,
      documents: allDocuments,
      isFiltersApplied: isFiltersApplied(),
      isLoading: isLoadingDocuments,
      isUploadCardOpen: state.isUploadCardOpen,
      loadNextPage: fetchNextPage,
      parties: parties ?? [],
      searchValue: state.searchValue,
      selectedDocumentType: state.selections.documentTypeId,
      selectedTab: state.selections.tab,
      selectedParties: state.selections.parties,
      selectedTags: state.selections.tags,
      setCurrentPage: makeUpdateSetter("currentPage"),
      setIsUploadCardOpen: makeUpdateSetter("isUploadCardOpen"),
      setSearchValue: makeUpdateSetter("searchValue"),
      setSelectedDocumentType: makeUpdateSetter("selections.documentType"),
      setSelectedTab: makeUpdateSetter("selections.tab"),
      setSelectedParties,
      setSelectedTags,
      setSortingOption: makeUpdateSetter("sortingOption"),
      sortingOption: state.sortingOption,
      documentsInProgress: inProgressDocuments ?? 0,
      tags,
      totalDocumentCount: data?.pages[0]?.total ?? 0,
      uploadDocuments,
      setUploadOpen: makeUpdateSetter("isUploadCardOpen"),
      isUploadingFiles: state.isUploadingFiles,
      onUploadClick,
      inProgressDocuments,
      validInProgressDocuments,
      canUploadMoreDocuments,
    }),
    [
      allDocuments,
      data,
      documentTypes,
      isLoadingDocuments,
      fetchNextPage,
      isFetchingNextPage,
      parties,
      state,
      tags,
      inProgressDocuments,
    ]
  );

  const onSelectDocumentType = (documentTypeId?: string) => {
    makeUpdateSetter("selections.documentTypeId")(
      documentTypeId?.toLowerCase() === "all" ? null : documentTypeId
    );
  };

  return {
    currentPage: state.currentPage,
    isFiltersApplied,
    isUploadingFiles: state.isUploadingFiles,
    myDocumentsContext,
    onUploadClick,
    searchValue: state.searchValue,
    selectedDocumentType: state.selections.documentTypeId,
    setCurrentPage: makeUpdateSetter("currentPage"),
    setSearchValue: makeUpdateSetter("searchValue"),
    setSelectedDocumentType: onSelectDocumentType,
    setUploadOpen: makeUpdateSetter("isUploadCardOpen"),
    totalDocumentCount: data?.pages[0]?.total ?? 0,
    uploadDocuments,
    uploadOpen: state.isUploadCardOpen,
    canUploadMoreDocuments,
  };
};

import { HTMLAttributes, useContext } from "react";

import { cn } from "../../../utils";
import { FilterDropdown } from "../../actions/inputs/filter-dropdown";
import { Filter } from "./filterable-table";
import FilterableTableContext from "./filterable-table-context";

interface TableFilterDropdownProps extends HTMLAttributes<HTMLDivElement> {
  filter: Filter;
  maxBadges?: number;
  classNames?: {
    container?: string;
  };
}

export const TableFilterDropdown = ({
  filter,
  classNames,
  maxBadges,
  ...rest
}: TableFilterDropdownProps) => {
  const { toggleFilterOption, clearFilterValues, filterValues } = useContext(
    FilterableTableContext
  );

  const values = filterValues[filter.accessorKey] ?? [];

  return (
    <FilterDropdown
      label={filter.label}
      icon={filter.icon}
      options={filter.options}
      selectedValues={values}
      disabled={filter.disabled}
      placeholder={filter.placeholder}
      hideClearOption={filter.hideClearOption}
      classNames={{
        container: cn(classNames?.container),
      }}
      maxBadges={maxBadges}
      onToggleOption={(option) => toggleFilterOption(filter, option)}
      onClear={() => clearFilterValues(filter)}
      {...rest}
    />
  );
};

import { useQuery } from "react-query";

import { getAllParties } from "@atlas-ui/services";

export const useParties = (
  options: { documentTypeIds?: string[]; insights?: boolean } = {}
) => {
  const { documentTypeIds, insights } = options;
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["parties", documentTypeIds, insights],
    refetchOnWindowFocus: false,
    queryFn: () =>
      getAllParties({
        documentTypeIds: documentTypeIds ?? [],
        insights,
      }),
  });

  return {
    parties: data ?? [],
    isLoadingParties: isLoading,
    refetchParties: refetch,
  };
};

import { StarIcon } from "@heroicons/react/24/solid";

import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

export const renderFavoriteIcon = (isFavorite: boolean) => {
  const props = {
    "data-testid": "favorite-icon",
    className: `h-5 w-5 ${isFavorite ? "text-primary" : "text-input"}`,
  };

  return <StarIcon {...props} />;
};

export const parseTermString = (input: string) => {
  const normalizedInput = input?.trim().toLowerCase() ?? "";

  const unitsMapping: { [key: string]: string } = {
    day: "Days",
    month: "Months",
    year: "Years",
  };

  const regex = /(\d+)\s*(day|month|year)s?/i;
  const match = regex.exec(normalizedInput);

  if (match) {
    const count = parseInt(match[1], 10);
    const unit = match[2].toLowerCase();
    return `${count} ${unitsMapping[unit]}`;
  } else {
    return "";
  }
};

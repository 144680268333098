import React, {
  FC,
  HTMLAttributes,
  PropsWithChildren,
  useContext,
} from "react";

import { RowContext } from "@atlas-ui/components";
import { Document } from "@atlas-ui/types";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { Row } from "@tanstack/react-table";

import { cn } from "@/lib/utils";

import dayjs from "dayjs";

interface InsightsCreatedAtCellProps
  extends PropsWithChildren,
    HTMLAttributes<HTMLDivElement> {
  row: Row<Document>;
  classNames?: {
    dropdownButton?: string;
  };
}

export const InsightsCreatedAtCell: FC<InsightsCreatedAtCellProps> = ({
  row,
  classNames,
  children,
  ...rest
}) => {
  const { setIsExpanded, isExpanded } = useContext(RowContext);

  const document = row.original;
  const formattedDate = document._isInvalid
    ? ""
    : dayjs(document.createdAt).format("MMM DD, YYYY");

  const onExpand = () => setIsExpanded?.(!isExpanded);

  return (
    <div
      className="flex justify-between items-start h-full relative group"
      {...rest}
    >
      <span className="pt-[6px]">{formattedDate}</span>
      <div className={"pt-[2px] flex gap-2 h-[26px]"}>
        {children}
        <button
          className={cn(
            "flex items-center gap-2 text-xs font-normal leading-4 skip-close-upload",
            classNames?.dropdownButton
          )}
          data-testid={"expand-document-button"}
          onClick={onExpand}
        >
          {isExpanded ? (
            <ChevronUpIcon className={"w-3 h-3 text-foreground"} />
          ) : (
            <ChevronDownIcon className={"w-3 h-3 text-foreground"} />
          )}
        </button>
      </div>
    </div>
  );
};

import { FC, HTMLAttributes } from "react";

import { cn } from "../../utils";
import { Spinner } from "./spinner";

export const LoadingScreen: FC<HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => (
  <div
    className={cn(
      "flex flex-col items-center justify-center h-full w-full flex-1",
      className
    )}
    data-testid="loading-screen"
    role="status"
    aria-label="Loading"
    {...props}
  >
    {props.children ? props.children : <Spinner />}
  </div>
);

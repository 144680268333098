import { FC } from "react";

import { Spinner } from "@atlas-ui/components";
import { Document, DocumentStatus } from "@atlas-ui/types";
import { Row } from "@tanstack/react-table";

import { InsightsEffectiveDateCell } from "@/app/compare/components/insights-effective-date-cell";

interface MyDocumentsEffectiveDateCellProps {
  row: Row<Document>;
}

export const MyDocumentsEffectiveDateCell: FC<
  MyDocumentsEffectiveDateCellProps
> = ({ row }) => {
  if (row.original._isInvalid) {
    return <span data-testid={"invalid-document"} />;
  }

  return (
    <InsightsEffectiveDateCell row={row}>
      {(effectiveDate) => (
        <>
          {![
            DocumentStatus.preprocessInProgress,
            DocumentStatus.docxToPdfInProgress,
            DocumentStatus.kvpInProgress,
          ].includes(row.original.status) ? (
            <span className={"line-clamp-1"} data-testid="effective-date">
              {effectiveDate}
            </span>
          ) : (
            <div className={"flex"} data-testid="spinner-container">
              <Spinner size={4} data-testid="spinner" />
            </div>
          )}
        </>
      )}
    </InsightsEffectiveDateCell>
  );
};

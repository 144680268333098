import API from "./api";

export interface SendMessageRequest {
  message: string;
  documentIds?: string[];
  threadId?: string;
}

export const sendMessageToAgent = async (
  sendMessageRequest: SendMessageRequest
) => {
  const { data } = await API.post("/agent/send-message", {
    message: sendMessageRequest.message,
    documentIds: sendMessageRequest.documentIds,
    threadId: sendMessageRequest.threadId,
  });

  return data;
};

import { createContext } from "react";

import { Subject } from "rxjs";

export interface EventData {
  id: string;
  [key: string]: unknown;
}

export enum EventType {
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  CREATE = "CREATE",
  ADMIN_KVP_EXTRACT_CONCLUDED = "ADMIN_KVP_EXTRACT_CONCLUDED",
}

export enum Entity {
  DOCUMENT = "document",
  USER = "user",
  SECTION = "section",
  COMPARISON = "comparison",
  ADMIN_KVP_EXTRACT = "admin-kvp-extract",
  INSIGHT_RESULT = "insight-result",
  INSIGHT = "insight",
  USAGE = "usage",
  AGENT = "docsnap-agent",
}

export interface SocketEvent {
  event: EventType;
  entity: Entity;
  data: EventData;
}

interface RealtimeContextContextType {
  eventsSubject?: Subject<SocketEvent>;
  isConnected?: boolean;
}

export const RealtimeContext = createContext<RealtimeContextContextType>({});

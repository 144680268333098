import { cloneElement, FC, ReactElement, ReactNode } from "react";

export const TableSortLabel: FC<{
  label: string;
  icon: ReactNode;
}> = ({ label, icon }) => (
  <div
    className={"gap-[8px] flex items-center"}
    role="button"
    aria-label={`Sort by ${label}`}
  >
    {icon &&
      cloneElement(icon as ReactElement, {
        className: "h-4 w-4 text-foreground",
        "aria-hidden": true,
      })}
    <span>{label}</span>
  </div>
);

import { useQuery } from "react-query";

import {
  getDocumentTypes,
  GetDocumentTypesWithCountParams,
} from "@atlas-ui/services";
import { DocumentType } from "@atlas-ui/types";

import { useDocumentTypesWithCount } from "./use-document-types-with-count";

interface UseDocumentTypesProps {
  withDocuments?: boolean;
  insightsEnabled?: boolean;
  fetchCount?: boolean;
  params?: GetDocumentTypesWithCountParams;
}

export const useDocumentTypes = (
  {
    withDocuments,
    insightsEnabled,
    fetchCount,
    params,
  }: UseDocumentTypesProps = {
    withDocuments: false,
    insightsEnabled: false,
    fetchCount: false,
  }
) => {
  const { isLoading, data } = useQuery<DocumentType[]>({
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    queryKey: ["document-types", withDocuments, insightsEnabled],
    queryFn: () =>
      getDocumentTypes({
        withDocuments: withDocuments,
        insightsEnabled: insightsEnabled,
      }),
  });

  const { documentTypesWithCount: counts, isLoadingDocumentTypesWithCount } =
    useDocumentTypesWithCount({
      enabled: fetchCount && !isLoading,
      params,
    });

  const prepareData = (data?: DocumentType[]) => {
    if (!data || !counts) {
      return [];
    }

    if (!fetchCount) {
      return data;
    }

    return data.map((documentType) => {
      const count = counts?.find((count) => count.id === documentType.id);

      return {
        ...documentType,
        count: count?.count ?? 0,
      };
    });
  };

  return {
    documentTypes: prepareData(data),
    isLoadingDocumentTypes: isLoading || isLoadingDocumentTypesWithCount,
  };
};
